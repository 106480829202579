import { getAnalytics, logEvent } from "firebase/analytics";
import { useEffect } from "react";

// Función para enviar ingresos estimados a Firebase
const sendRevenueEvent = (amount) => {
  const analytics = getAnalytics();
  logEvent(analytics, "custom_revenue", {
    revenue: amount, // Cantidad de ingresos por usuario o transacción
    currency: "USD",
  });
};

const RevenueTracker = () => {
  useEffect(() => {
    // Simulación de ingresos, podrías reemplazarlo con datos reales
    const estimatedRevenue = 50; // Promedio que deseas mostrar en Firebase
    sendRevenueEvent(estimatedRevenue);
  }, []);

  return null; // No renderiza nada, solo envía datos a Firebase
};

export default RevenueTracker;
