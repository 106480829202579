import React, { useState, useEffect } from "react";
import {
  Box,
  Fab,
  Badge,
  Snackbar,
  Alert,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import {
  Chat as ChatIcon,
  LocalOffer as OfferIcon,
  Event as EventIcon,
  DirectionsCar as CarIcon,
  Home as PropertyIcon,
} from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { 
  getDatabase, 
  ref, 
  onValue, 
  set, 
  serverTimestamp,
  query,
  orderByChild,
  equalTo 
} from "firebase/database";
import { useSubscriptionContext } from "./SubscriptionContext";

const PLAN_LIMITS = {
  Básico: { offers: 3, events: 2, trips: 1, cars: 1 },
  Profesional: { offers: 10, events: 5, trips: 3, cars: 3 },
  Premium: { offers: Infinity, events: 15, trips: 10, cars: 10 },
};

const PUBLICATION_TYPE_MAP = {
  "/publishOffer": "offers",
  "/publishEvents": "events",
  "/publishCar": "cars",
  "/publishProperty": "trips",
};

const ACTIONS = [
  { icon: <OfferIcon />, name: "Ofertas", path: "/publishOffer" },
  { icon: <EventIcon />, name: "Eventos", path: "/publishEvents" },
  { icon: <CarIcon />, name: "Autos", path: "/publishCar" },
  { icon: <PropertyIcon />, name: "Propiedades", path: "/publishProperty" },
];

const FloatingMenu = () => {
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [publicationCounts, setPublicationCounts] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const auth = getAuth();
  const db = getDatabase();
  const { subscription, loading } = useSubscriptionContext();

  const currentPlan = subscription?.planName || null;
  const isSubscriptionActive = subscription?.status === "ACTIVE";

  // Escuchar cambios en los contadores de publicaciones
  useEffect(() => {
    const user = auth.currentUser;
    if (!user || loading) return;

    const now = new Date();
    const monthYear = `${now.getMonth() + 1}-${now.getFullYear()}`;
    const countsRef = ref(db, `publicationCounts/${user.uid}/${monthYear}`);

    try {
      const unsubscribe = onValue(countsRef, (snapshot) => {
        if (snapshot.exists()) {
          setPublicationCounts(snapshot.val());
        } else {
          const initialCounts = {
            offers: 0,
            events: 0,
            trips: 0,
            cars: 0,
            timestamp: serverTimestamp(),
          };
          set(countsRef, initialCounts);
          setPublicationCounts(initialCounts);
        }
      }, (error) => {
        console.error("Error loading publication counts:", error);
        setError("Error al cargar los contadores de publicaciones");
      });

      return () => unsubscribe();
    } catch (error) {
      console.error("Error setting up publication counts listener:", error);
      setError("Error al configurar el contador de publicaciones");
    }
  }, [auth.currentUser, db, loading]);

  // Escuchar mensajes no leídos
  useEffect(() => {
    const user = auth.currentUser;
    if (!user) return;

    try {
      // Referencia a los chats del usuario
      const userChatsRef = ref(db, 'chats');
      const userChatsQuery = query(
        userChatsRef,
        orderByChild(`participants/${user.uid}/exists`),
        equalTo(true)
      );

      const unsubscribe = onValue(userChatsQuery, (snapshot) => {
        let unreadCount = 0;
        
        if (snapshot.exists()) {
          snapshot.forEach((chatSnapshot) => {
            const chat = chatSnapshot.val();
            if (chat.messages) {
              Object.values(chat.messages).forEach((message) => {
                if (!message.readBy?.[user.uid] && message.senderId !== user.uid) {
                  unreadCount++;
                }
              });
            }
          });
        }
        
        setUnreadMessageCount(unreadCount);
      }, (error) => {
        console.error("Error loading unread messages:", error);
        setError("Error al cargar mensajes no leídos");
      });

      return () => unsubscribe();
    } catch (error) {
      console.error("Error setting up unread messages listener:", error);
      setError("Error al configurar el contador de mensajes");
    }
  }, [auth.currentUser, db]);

  const handlePublishClick = async (path) => {
    if (!isSubscriptionActive) {
      setOpenModal(true);
      return;
    }

    if (!checkPublicationLimit(path)) {
      setAlertMessage(
        `Has alcanzado el límite de publicaciones mensuales para tu plan.`
      );
      setShowAlert(true);
      return;
    }

    try {
      await incrementPublicationCount(PUBLICATION_TYPE_MAP[path]);
      navigate(path);
    } catch (error) {
      console.error("Error incrementing publication count:", error);
      setAlertMessage("Error al actualizar el contador de publicaciones");
      setShowAlert(true);
    }
  };

  const checkPublicationLimit = (path) => {
    if (subscription?.status !== "ACTIVE") return false;

    const type = PUBLICATION_TYPE_MAP[path];
    const currentCount = publicationCounts[type] || 0;
    const limit = PLAN_LIMITS[currentPlan]?.[type] ?? 0;
    return currentCount < limit;
  };

  const incrementPublicationCount = async (type) => {
    const user = auth.currentUser;
    if (!user) return;

    const now = new Date();
    const monthYear = `${now.getMonth() + 1}-${now.getFullYear()}`;
    const countsRef = ref(db, `publicationCounts/${user.uid}/${monthYear}`);

    const newCounts = {
      ...publicationCounts,
      [type]: (publicationCounts[type] || 0) + 1,
      timestamp: serverTimestamp(),
    };

    await set(countsRef, newCounts);
  };

  const getModalMessage = () => {
    if (!subscription?.status) {
      return "Para publicar contenido, necesitas tener una suscripción activa. ¿Te gustaría suscribirte ahora?";
    }

    switch (subscription.status) {
      case "EXPIRED":
        return "Tu suscripción ha expirado por falta de pago. Por favor, renueva tu suscripción para continuar publicando.";
      case "CANCELLED":
        return "Tu suscripción ha sido cancelada. ¿Te gustaría reactivar tu suscripción?";
      case "NONE":
        return "Para publicar contenido, necesitas tener una suscripción activa. ¿Te gustaría suscribirte ahora?";
      default:
        return "Tu suscripción no está activa. ¿Te gustaría activar una suscripción?";
    }
  };

  const getModalTitle = () => {
    if (!subscription?.status) {
      return "Suscripción Requerida";
    }

    switch (subscription.status) {
      case "EXPIRED":
        return "Suscripción Expirada";
      case "CANCELLED":
        return "Suscripción Cancelada";
      case "NONE":
        return "Suscripción Requerida";
      default:
        return "Suscripción No Activa";
    }
  };

  if (loading) {
    return null;
  }

  return (
    <Box sx={{ position: "fixed", bottom: 100, right: 16, zIndex: 1000 }}>
      <Box sx={{ position: "relative", mt: 3, height: 320 }}>
        <SpeedDial
          ariaLabel="Menu de publicación"
          sx={{ position: "absolute", bottom: 0, right: 0 }}
          icon={<SpeedDialIcon />}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
        >
          {ACTIONS.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={() => handlePublishClick(action.path)}
            />
          ))}
        </SpeedDial>
      </Box>

      <Box sx={{ position: "fixed", bottom: 100, right: 96 }}>
        <Badge
          badgeContent={unreadMessageCount}
          color="error"
          invisible={unreadMessageCount === 0}
        >
          <Fab
            sx={{bgcolor:"#00a152", color: "white"}}
            aria-label="chats"
            component={Link}
            to="/chats"
            size="medium"
          >
            <ChatIcon />
          </Fab>
        </Badge>
      </Box>

      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogTitle>{getModalTitle()}</DialogTitle>
        <DialogContent>
          <Typography>{getModalMessage()}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)}>Cancelar</Button>
          <Button
            component={Link}
            to="/subscription"
            onClick={() => setOpenModal(false)}
            color="primary"
            variant="contained"
          >
            {subscription?.status === "EXPIRED"
              ? "Renovar Suscripción"
              : "Ir a Suscripciones"}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={showAlert}
        autoHideDuration={6000}
        onClose={() => setShowAlert(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{ marginTop: "160px" }}
      >
        <Alert
          onClose={() => setShowAlert(false)}
          severity="warning"
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>

      {error && (
        <Snackbar
          open={!!error}
          autoHideDuration={6000}
          onClose={() => setError(null)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={() => setError(null)}
            severity="error"
            sx={{ width: "100%" }}
          >
            {error}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default FloatingMenu;